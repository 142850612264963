$(function () {
    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    $("#cookieconsent").on("click", ".accept", function (e) {
        console.log("Accept")
        $("#cookieconsent").hide().removeClass('d-flex');
        setCookie('cookieconsent', 'allow', 365);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'cookiesConsent'
        });
    });

    $("#cookieconsent").on("click", ".deny", function (e) {
        console.log("DENY")
        $("#cookieconsent").hide().removeClass('d-flex');
        setCookie('cookieconsent', 'deny', 365);
    });
});