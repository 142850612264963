$(function () {

    validateForm = function () {
        $(".signupform form").validate({
            rules: {
                Name: "required",
                Phone: {
                    required: true,
                    digits: true,
                    minlength: 8,
                    maxlength: 8
                },
                Email: {
                    required: true,
                    email: true
                },
                Consent: "required"
            },
            messages: {
                Name: "Indtast venligst dit navn",
                Phone: {
                    required: "Indtast venligst dit telefonnummer",
                    digits: "Indtast venligst kun tal",
                    minlength: "Indtast venligst 8 tal",
                    maxlength: "Indtast venligst kun 8 tal"
                },
                Email: {
                    required: "Indtast venligst din email adresse",
                    email: "Indtast venligst en gyldig email adresse"
                },
                Consent: "Husk at acceptere vores privatlivspolitik",
            },
            errorElement: "div",
            errorPlacement: function (error, element) {
                if (element.attr("type") == "checkbox") {
                    error.insertAfter($(element).siblings('label'));
                } else {
                    error.insertAfter($(element));
                }
            },
            highlight: function (label) {
                $(label).closest('.form-group').removeClass('has-success').addClass('has-error');
                $(label).closest('.form-check').addClass('has-error');
            },
            success: function (label) {
                label.closest('.form-group').removeClass('has-error').addClass('has-success');
                label.closest('.form-check').removeClass('has-error');
            }
        });

    }
 
    closeSignUpForm = function () {
        $(".class-item").removeClass("open");
        $(".signup").show();
        $('.signupform').empty()
    }

    $(".signup").on("click", function () {
        var element = $(this).parents(".class-item");
        var guid = element.data("guid");
        $(".class-item").removeClass("open")
        $(element).addClass("open")

        $(".signup").show();
        $(this).hide();

        $.ajax({
            type: "GET",
            url: '/Umbraco/Surface/SignupForm/RenderSignUpForm/' + guid,
            dataType: "html",
            success: function (result) {
                $('.signupform', element).html(result);

                validateForm()
            },
            error: function (request, status, error) {
                //Do Something on Failure
            }
        });


    });

    $(document).click(function () {
        closeSignUpForm();
    });

    $("#classes").on("click", ".close", function (e) {
        closeSignUpForm();
        e.stopPropagation();
    });

    $("#classes").on("click", ".card", function (e) {
        e.stopPropagation();
    });

    $("#classes").on("click", ".submit", function (e) {
        var card = $(this).parents(".card");
        var form = $("form", card);
        var loader = $(".loader", card);

        if (form.valid()) {
            $(".submit", form).attr("disabled", true);
            loader.removeClass("d-none");

            $.ajax({
                type: "POST",
                url: '/umbraco/surface/SignupForm/Submit',
                data: form.serialize(),
                success: function (result) {
                    $(".submit", form).attr("disabled", false);
                    loader.addClass("d-none");
                    $(".card-body", card).html("<h2>Tak for din tilmelding</h2><p>Du vil blive kontaktet hurtigst muligt</p>");
                },
                error: function (request, status, error) {
                    $(".submit", form).attr("disabled", false);
                    loader.addClass("d-none");
                    $(".card-body", card).html("<h2>Der opstod en fejl, pr�v igen</h2>");
                }
            });
        }        
    });
});