$(function () {
    'use strict';

    $(window).scroll(function () {
        var st = $(window).scrollTop();

        if ($("#hero").length) {
            var wh = $("#hero").height();
            setHeaderColor(st, wh)
        }

        if ($("#topimage").length) {
            var wh = $("#topimage").height();
            setHeaderColor(st, wh)
        }
    });

    $("#nav-toggle").click(function () {
        $(this).toggleClass("open");
        $("#menu").toggleClass("open");
    });

    setHeaderColor($(window).scrollTop(), window.innerHeight)
});

setHeaderColor = function (scrollTop, windowHeight) {
    if (!$("#menu").hasClass("open")) {
        if (scrollTop > windowHeight - 100) {
            if (!$("#logo").hasClass("invert")) {
                $("#logo").addClass("invert")
            }
            if (!$("#nav-toggle").hasClass("invert")) {
                $("#nav-toggle").addClass("invert")
            }
            if (!$("header").hasClass("fixed")) {
                $("header").addClass("fixed")
            }
        } else {
            if ($("#logo").hasClass("invert")) {
                $("#logo").removeClass("invert")
            }
            if ($("#nav-toggle").hasClass("invert")) {
                $("#nav-toggle").removeClass("invert")
            }
            if ($("header").hasClass("fixed")) {
                $("header").removeClass("fixed")
            }
        }
    }
}